/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

/* ReactSelect Component Styles */

/* Style react-select inputs for react-bootstrap and Bootswatch's 'Darkly' theme */

/* General Styles */
.ReactSelect {
	transition: opacity 0.2s ease-in-out;
}
.ReactSelect .ReactSelect__indicator-separator {
	display: none;
}
.ReactSelect.ReactSelect--is-disabled {
	opacity: 0.4 !important;
}
.ReactSelect .ReactSelect__menu {
	text-align: left;
}
.ReactSelect .ReactSelect__control.ReactSelect__control--is-focused {
	outline: 0;
    box-shadow: 0 0 0 .25rem rgba(116,73,180,.25);
	/*box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25%);*/
	/*box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);*/
}
.ReactSelect input {
	box-shadow: none !important;
}

/* Bootstrap/Darkly Theme Styles */
.ReactSelect .ReactSelect__control,
.ReactSelect .ReactSelect__menu {
	background-color: #303030 !important;
	border-color:  #6b6b6b !important;
	color: #f8f9fa !important;
	box-shadow: none;
}
.ReactSelect .ReactSelect__control .ReactSelect__single-value {
	color: #f8f9fa !important;
}
.ReactSelect .ReactSelect__menu .ReactSelect__option:hover,
.ReactSelect .ReactSelect__menu .ReactSelect__option.ReactSelect__option--is-focused {
	background-color: #444 !important;
}
.ReactSelect .ReactSelect__menu .ReactSelect__option.ReactSelect__option--is-selected {
	background-color: #8964c0 !important;
}

.ReactSelect.ReactSelect--dark .ReactSelect__control,
.ReactSelect.ReactSelect--dark .ReactSelect__menu {
	background-color: #2a2a2a !important;
	border-color:  #666666 !important;
	color: #edeff2 !important;
}
.ReactSelect.ReactSelect--dark .ReactSelect__control .ReactSelect__single-value {
	color: #edeff2 !important;
}
.ReactSelect.ReactSelect--dark .ReactSelect__menu .ReactSelect__option:hover,
.ReactSelect.ReactSelect--dark .ReactSelect__menu .ReactSelect__option.ReactSelect__option--is-focused {
	background-color: #3e3e3e !important;
}

/* Validation Styles */
.was-validated .ReactSelect.ReactSelect--is-valid .ReactSelect__control {
	/*border-color: #198754 !important;*/
	/*border-color: #00bc8c;*/
	padding-right: calc(1.5em + 3px);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	/*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300bc8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");*/
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);

}
.was-validated .ReactSelect.ReactSelect--is-valid .ReactSelect__control.ReactSelect__control--is-focused {
	box-shadow: 0 0 0 0.25rem rgb(0 188 140 / 25%);
	/*box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);*/
}
.was-validated .ReactSelect.ReactSelect--is-valid .ReactSelect__indicator {
	/*color: #198754;*/
}

.was-validated .ReactSelect.ReactSelect--is-invalid .ReactSelect__control {
	border-color: #dc3545 !important;
	padding-right: calc(1.5em + 3px);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.was-validated .ReactSelect.ReactSelect--is-invalid .ReactSelect__control.ReactSelect__control--is-focused {
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .ReactSelect.ReactSelect--is-invalid .ReactSelect__indicator {
	color: #dc3545;
}

.was-validated .ReactSelect.ReactSelect--is-invalid~.invalid-feedback {
	display: block;
}

/* Size Styles */
/* -small- */
.ReactSelect.ReactSelect--size-sm .ReactSelect__control {
	min-height: 26px;
	font-size: 14px;
	/*height: 26px;*/
}
.ReactSelect.ReactSelect--size-sm .ReactSelect__value-container {
	height: 26px;
	padding: 0 6px
}
.ReactSelect.ReactSelect--size-sm .ReactSelect__input {
	/*margin: 0px;*/
}
.ReactSelect.ReactSelect--size-sm .ReactSelect__indicators {
	height: 26px;
}

/* -large- */
/* TODO */
