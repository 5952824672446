/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

/* Main App Header Styles */

/* Link Color */
#Header.navbar-dark .navbar-nav .show > .nav-link,
#Header.navbar-dark .navbar-nav .active > .nav-link,
#Header.navbar-dark .navbar-nav .nav-link.show,
#Header.navbar-dark .navbar-nav .nav-link.active {
  color: #a98ed1;
}

/* Brand */
#Header.navbar {
  padding-top: 1px;
  padding-bottom: 1px;
}
#Header.navbar .navbar-brand div {
  font-size: 18px;
  margin-top: 5px !important;
}

/* User Profile Dropdown */
/* -dropdown toggle (photo icon)- */
#ProfileDropdown .dropdown-toggle {
  padding: 9px 10px 9px 0px;
}
#ProfileDropdown .dropdown-toggle .ProfilePic {
  width: 36px;
  transition: all 0.2s ease-in-out;
}
#ProfileDropdown .dropdown-toggle .ProfilePic:hover {
  opacity: 0.6;
}
#ProfileDropdown .dropdown-toggle:after {
  display: none;
}

/* -dropdown arrow- */
#ProfileDropdown .dropdown-menu:after,
#ProfileDropdown .dropdown-menu:before {
  bottom: 100%;
  right: 27px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
#ProfileDropdown .dropdown-menu:after {
  border-bottom-color: #222;
  border-width: 4px;
  margin-right: -4px;
}
#ProfileDropdown .dropdown-menu:before {
  border-bottom-color: #444;
  border-width: 5px;
  margin-right: -5px;
}

/* -dropdown menu content- */
#ProfileDropdown .dropdown-menu {
  margin-top: 0px;
  white-space: nowrap;
}
#ProfileDropdown .dropdown-item:hover {
  background: none;
  cursor: auto;
}

#ProfileDropdown .ProfileDropdownCard .ProfilePic {
  width: 100px;
}