/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

/* FileBlock Component Styles */

/* General Styles */
.FileBlock {
	display: block;
	width: 100%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd !important;
	background-color: #262626;
    background-clip: padding-box;
    border: 1px solid #616161;
    border-radius: 0.25rem;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background-color .15s ease-in-out;
}

.FileBlock:hover {
	background-color: #303030;
}