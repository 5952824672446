/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

/* ImageInput Component Styles */

/* General Styles */
.ImageInput {
	position: relative;
	width: 275px;
	min-height: 275px;
	background: #303030;
	color: rgba(255, 255, 255, 0.6);
	border: 2px dashed rgba(255, 255, 255, 0.6);
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}
.ImageInput:hover {
	background: #444;
}
.ImageInput:before {
	opacity: 0;
}
.ImageInput:after {
	content: 'Select Image';
	position: absolute;
	font-size: 28px;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.ImageInput > input[type="file"] {
	position: absolute;
	top: -1px;
	left: -1px;
	bottom: 1px;
	right: 5px;
	opacity: 0;
	z-index: 101;
}
.ImageInput:focus-within {
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba(116,73,180,.25);
}

/* Validation Styles */
.was-validated .ImageInput.ImageInput--is-valid:after {
	padding-right: calc(1.5em + 3px);
	background-color: none;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.was-validated .ImageInput.ImageInput--is-valid:focus-within {
	box-shadow: 0 0 0 0.25rem rgb(0 188 140 / 25%);
}
.was-validated .ImageInput.ImageInput--is-invalid {
	border-color: #dc3545 !important;
	padding-right: calc(1.5em + 3px);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.was-validated .ImageInput.ImageInput--is-invalid:focus-within {
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .ImageInput.ImageInput--is-invalid~.invalid-feedback {
	display: block;
}

/* Image Preview Styles */
.ImageInput > img {
	width: 100%;
	top: -1px;
	left: -1px;
	z-index: 98;
}
.ImageInput.ImageInput--has-image {
	min-height: 0;
	background: none;
	border: none;
}
.ImageInput.ImageInput--has-image > img {
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 4px;
	padding: 3px;
}

/* Image Preview 'Click to Change' Overlay Styles */
.ImageInput.ImageInput--has-image:before {
	content: 'Click to Change Image';
	position: absolute;
	font-size: 28px;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;
	opacity: 0;
	transition: opacity 0.4s ease-in-out;
}
.ImageInput.ImageInput--has-image:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 4px;
	bottom: 4px;
	right: 4px;
	transform: none;
	z-index: 99;
	transition: background-color 0.4s ease-in-out;
}
.ImageInput.ImageInput--has-image:hover:before {
	opacity: 1;
}
.ImageInput.ImageInput--has-image:hover:after {
	background-color: rgba(0, 0, 0, 0.6);
}