/* *** Fevre River Database *** */
/* (C) 2022 - Joti Software, LLC */

/* Main App CSS */

/* TODO: review for unused/unnecessary styles */


/* --- General Element Styles --- */
a {
	color: #a98ed1;
	text-decoration: none !important;
	transition: all 0.2s ease-in-out;
}

a:hover {
	color: #7449b4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: rgba(255, 255, 255, 0.6);
}

hr {
	border-color: rgba(255, 255, 255, 0.25);
}


/* --- Bootstrap Override Styles --- */
/* Borders */
/* used to maintain alignment when using a visible border on one side of an element */
.border-clear { border-color: transparent !important; }
.border-top-clear { border-top-color: transparent !important; }
.border-end-clear { border-right-color: transparent !important; }
.border-bottom-clear { border-bottom-color: transparent !important; }
.border-start-clear { border-left-color: transparent !important; }

/* Button */
.btn-xs,
.btn-group-xs > .btn {
	padding: .1rem .25rem;
	font-size: .75rem;
	border-radius: .15rem;
}

.btn-surprise {
    color: #fff;
    background-color: #8964c0;
    border-color: #8964c0;
}
.btn-surprise:hover {
    background-color: #7449b4;
    border-color: #6b43a6;
}

.btn-outline-surprise {
    color: #8964c0;
    border-color: #8964c0;
}
.btn-outline-surprise:hover {
    color: #fff;
    background-color: #7449b4;
}

.btn-outline-input-match {
    color: #888;
    border-color: #6b6b6b;
    background-color: #2a2a2a;
}
.btn-outline-input-match:hover {
    color: #f8f9fa;
    background-color: #303030;
}

/* Card */
.card.card-xs { font-size: 12px; }
.card.card-xs .card-header h6 { font-size: 14px; }
.card.card-xs .card-header { padding: 0.5rem 0.8rem; }
.card.card-xs .list-group-item { padding: 0.4rem 0.8rem; text-overflow: ellipsis;}
.card.card-xs .card-body { padding: 0.6rem 0.8rem; }

.card.card-sm .card-header { padding: 0.6rem 1rem; }
.card.card-sm .list-group-item { padding: 0.5rem 1rem; text-overflow: ellipsis;}
.card.card-sm .card-body { padding: 0.75rem 1rem; }

.card.card-list-hover .list-group-item { cursor: pointer; /*transition: all 0.2s ease-in-out;*/ }
.card.card-list-hover .list-group-item:hover { background: #3a3a3a; }

.card.accordion-card .card-header {
	position: relative;
	display: flex;
	cursor: pointer;
	background-color: #303030;
	transition: background-color .15s ease-in-out;
}
.card.accordion-card:not(.collapsed) .card-header {
	background-color: #444444;
}
.card.accordion-card .card-header:after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23888888'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform .2s ease-in-out;
}
.card.accordion-card:not(.collapsed) .card-header:after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffffa6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}

/* Inputs */
label,
.form-label,
.form-check-label,
.form-floating > label {
	color: #888 !important;
}

.btn:focus,
input:focus,
.form-control:focus,
.form-check-input:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(116,73,180,.25);
}

textarea { resize: none; }
textarea.textarea-sm { font-size: 14px; }

input[type="search"] {
	height: 38px;
	padding: .375rem .75rem;
	border: 1px solid;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
	height: 1em;
	width: 1em;
	margin-left: 8px;
	margin-right: -2px;
	border-radius: 50em;
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3C!--%0AFont Awesome Pro 5.15.0 by @fontawesome - https://fontawesome.com%0ALicense - https://fontawesome.com/license (Commercial License)%0A--%3E%3Cpath fill='%238964c0' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'/%3E%3C/svg%3E") no-repeat 50% 50%;
	background-size: contain;
	opacity: 0;
	cursor: pointer;
	pointer-events: none;
}
input[type="search"]:focus::-webkit-search-cancel-button {
	opacity: 1;
	pointer-events: all;
}
input[type="search"]::-webkit-search-cancel-button:hover {
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3C!--%0AFont Awesome Pro 5.15.0 by @fontawesome - https://fontawesome.com%0ALicense - https://fontawesome.com/license (Commercial License)%0A--%3E%3Cpath fill='%237449b4' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}

textarea,
input[type="text"],
input[type="date"],
input[type="file"],
input[type="search"],
input[type="checkbox"],
.form-control:not([required]).is-valid,
.was-validated .form-control:not([required]):valid {
	background-color: #303030 !important;
	border-color:  #6b6b6b !important;
	color: #f8f9fa !important;
	background-image: none;
}

textarea.variant--dark,
input[type="text"].variant--dark,
input[type="date"].variant--dark,
input[type="file"].variant--dark,
input[type="search"].variant--dark,
input[type="checkbox"].variant--dark {
	background-color: #2a2a2a !important;
	border-color:  #656565 !important;
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
	color: #888;
}
input[type="date"].has-value::-webkit-datetime-edit-text,
input[type="date"].has-value::-webkit-datetime-edit-month-field,
input[type="date"].has-value::-webkit-datetime-edit-day-field,
input[type="date"].has-value::-webkit-datetime-edit-year-field {
	color: #f8f9fa;
}
input[type="date"]::-webkit-calendar-picker-indicator {
	filter: invert(100%);
	margin-right: -6px;
	margin-left: 2px;
}

input[type="checkbox"].form-check-input {
	cursor: pointer;
}
input[type="checkbox"].form-check-input:checked {
    background-color: #7449b4 !important;
    border-color: #7449b4 !important;
}

input:disabled {
	opacity: 0.4 !important;
}
input[type="file"]:disabled {
	opacity: 0.2 !important;
}

/* requires 'position: relative' on parent */
input[type="file"] + .spinner-border {	
	position: absolute;
	top: 11px;
	right: 2px;
}
input[type="file"] ~ .loading-label {
	position: absolute;
	top: 9px;
	left: 50%;
	transform: translateX(-50%);
	font-size: .875em;
	font-style: italic;
}

/* Input Validation */
.form-control[required].is-valid,
.was-validated .form-control[required]:valid {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.form-control.is-valid:not([required]),
.was-validated .form-control:not([required]):valid {
	padding-right: 0.75rem;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
	border-color: #dc3545 !important;
}

.is-invalid > .invalid-feedback {
	display: none;
}
.was-validated .is-invalid > .invalid-feedback {
	display: block;
}

/* Modal */
.modal-content {
	background-color: #222;
}

/* Popover */
.popover.popover-sm { font-size: 12px; }
.popover.popover-sm .popover-header { padding: 0.3rem 0.7rem; }
.popover.popover-sm .popover-body { padding: 0.7rem 0.7rem; }

.popover.location-card-popover { width: 225px; }
.popover.location-card-popover .popover-body { padding: 0; }

/* Table */
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgb(46, 46, 46);
}

.table-striped tbody tr {
	cursor: pointer;
	transition: background 0.2s ease-in-out;
}

.table-striped tbody tr:hover {
	background-color: rgba(116,73,180,.4);
}

/* --- React Animation Styles --- */
.slide-in-enter {
	opacity: 0.01;
	transform: translate(-40px, 0);
}

.slide-in-enter-active {
	opacity: 1;
	transform: translate(0, 0);
	transition: all 500ms ease-in;
}

/* --- Basic Site Layout Styles --- */
/* Main App */
/* -navbar offset- */
.App > .container-fluid {
	margin-top: 80px;
}

/* -loading overlay- */
.LoadingOverlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	z-index: 9999999;
}
.LoadingOverlay div {
	position: fixed;
	top: 50%;
	right: 50%;
	font-size: 16px;
	color: #dadada;
	transform: translate(50%, -50%);
}

.LoadingOverlay.LoadingOverlay--opaque {
	background: #0E0E0E;
}

/* --- Interface Specific Styles --- */

/* DataTables */
/* -table title- */
.DataTable__title:before {
	color: #888;
	font-size: 1.5rem;
}
.DatabaseView .DataTable__title:before { content: 'Items:'; }
.IncomingBooksView .DataTable__title:before { content: 'Books:'; }

/* -search- */
.dataTables_filter input[type="search"] {
	height: 31px;
	width: 200px !important;
	margin-right: 110px;
	font-size: .875rem !important;
}

.IncomingBooksView .dataTables_filter input[type="search"] {
	margin-right: 0;
}

/* -sorting indicators- */
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
	margin-right: 2px;
}
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
	color: #a98ed1;
}

/* Table Content */
.table-thumbnail {
	height: 35px;
}

/* --- Page Specific Styles --- */
/* Database View */
.DatabaseView .AddItem__button {
	position: absolute;
	right: 0;
}

/* Add/Edit Item UI */
.col.AddEditItemImage__Col {
	max-width: 299px;
}

.LocationLockButton {
	margin-top: 2px;
	opacity: 0.4;
	cursor: pointer;
	transition: opacity 0.2s ease-in-out;
}
.LocationLockButton:active {
	transform: scale(1.2);
}
.LocationLockButton.LocationLockButton--locked {
	opacity: 1;
}

/* Detail Page */
.col.DetailImage__Col {
	max-width: 324px;
}
.detail-pic {
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 4px;
	padding: 3px;
}

/* --- Utilities --- */
.cursor-pointer { cursor: pointer; }

.fade-in-on-load {
	opacity: 0;
	transition: opacity 1.5s ease-in-out;
}
.fade-in-on-load.fade-in { opacity: 1; }
