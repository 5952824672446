/* *** Fevre River Database *** */
/* (C) 2021 - Joti Software, LLC */

/* ColorBlock Component Styles */

/* General Styles */
.ColorBlock {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	margin: 5px;
	margin-bottom: -4px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 4px;
	cursor: pointer;
}
.ColorBlock:before {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 24px;
	height: 24px;
	border-radius: 2px;
	background-color: black;
}

.ColorBlock:active:not(.ColorBlock--display),
.ColorBlock.ColorBlock--selected 		{
	background-color: #fff;
}
.ColorBlock:active:not(.ColorBlock--display):before,
.ColorBlock.ColorBlock--selected:before	{
	border: 1px solid #000;
}
.ColorBlock:active:not(.ColorBlock--display) {
	transform: scale(1.04);
}

.ColorBlock.ColorBlock--display {
	width: 20px;
	height: 20px;
	margin-top: 0;
	cursor: default;
}
.ColorBlock.ColorBlock--display:before {
    width: 14px;
    height: 14px;
}

/* Colors */
/* TODO: investigate use of css custom variables to pass in color from options value */
.ColorBlock.ColorBlock--mediumvioletred:before	{ background-color: mediumvioletred; }
.ColorBlock.ColorBlock--hotpink:before			{ background-color: hotpink; }
.ColorBlock.ColorBlock--darkred:before			{ background-color: darkred; }
.ColorBlock.ColorBlock--red:before				{ background-color: red; }
.ColorBlock.ColorBlock--orangered:before		{ background-color: orangered; }
.ColorBlock.ColorBlock--orange:before			{ background-color: orange; }
.ColorBlock.ColorBlock--yellow:before			{ background-color: yellow; }
.ColorBlock.ColorBlock--lemonchiffon:before		{ background-color: lemonchiffon; }
.ColorBlock.ColorBlock--green:before			{ background-color: green; }
.ColorBlock.ColorBlock--lightgreen:before		{ background-color: lightgreen; }
.ColorBlock.ColorBlock--darkcyan:before			{ background-color: darkcyan; }
.ColorBlock.ColorBlock--cyan:before				{ background-color: cyan; }
.ColorBlock.ColorBlock--mediumblue:before		{ background-color: mediumblue; }
.ColorBlock.ColorBlock--deepskyblue:before		{ background-color: deepskyblue; }
.ColorBlock.ColorBlock--indigo:before			{ background-color: indigo; }
.ColorBlock.ColorBlock--mediumslateblue:before	{ background-color: mediumslateblue; }
.ColorBlock.ColorBlock--saddlebrown:before		{ background-color: saddlebrown; }
.ColorBlock.ColorBlock--tan:before				{ background-color: tan; }
.ColorBlock.ColorBlock--gray:before				{ background-color: gray; }
.ColorBlock.ColorBlock--lightgray:before		{ background-color: lightgray; }
.ColorBlock.ColorBlock--black:before			{ background-color: black; }
.ColorBlock.ColorBlock--white:before			{ background-color: white; }

/* Metallic Colors */
.ColorBlock.ColorBlock--gold:before {
	background: rgb(255,215,0);
	background: linear-gradient(135deg, rgba(255,215,0,1) 0%, rgba(255,255,255,1) 100%);
}
.ColorBlock.ColorBlock--silver:before {
	background: rgb(128,128,128);
	background: linear-gradient(135deg, rgba(128,128,128,1) 0%, rgba(255,255,255,1) 100%);
}